<template>
  <div>

    <Spinner v-if="loading === true" />

    <div
      v-if="loading === false"
      class="row"
    >
      <div class="col-md-6" />
      <div class="col-md-6 create-button">
<!--        <button-->
<!--          id="deleteMultiple"-->
<!--          type="button"-->
<!--          class="btn btn-outline-primary mb-2"-->
<!--          @click="confirmDeleteText(deletedArry)"-->
<!--        >-->
<!--          Delete Selected Elements-->
<!--        </button>-->
<!--        <button-->
<!--          type="button"-->
<!--          class="btn btn-primary mb-2 ml-1"-->
<!--          data-toggle="modal"-->
<!--          data-target="#createModal"-->
<!--        >-->
<!--          Create-->
<!--          &lt;!&ndash;            {{$store.state.appConfig.layout.queen}}&ndash;&gt;-->
<!--        </button>-->
<!--        <create-->
<!--          v-if="$router.currentRoute.name === 'languages'"-->
<!--          @refresh="getLanguages()"-->
<!--        />-->
      </div>
    </div>
    <basic-table
      v-if="loading === false"
      :deletable-page="false"
      ref="table"
      :columns="columns"
      :values="rows"
      :actions-obj="actionsArray"
      @dynamicAction="handleActions($event)"
      @choosenObject="getEditedObject($event)"
      @deleteItems="removeItems($event)"
      @deletedMultipleIds="catchIds($event)"
    />
    <edit
      v-if="$router.currentRoute.name === 'languages'"
      :item="editedObject"
      @refresh="getLanguages()"
    />
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import BasicTable from '../../../shared/tables/BasicTable.vue'
import Create from './create.vue'
import Edit from './edit.vue'
import languagesService from '../../../services/languagesService'
import Spinner from '@/shared/loader/Spinner.vue'

export default {
  components: {
    Spinner,
    BRow,
    BCol,
    Create,
    Edit,
    BasicTable,
  },
  data() {
    return {
      loading: false,
      actionsArray: [],
      editedObject: '',
      clientId: '',
      columns: [

        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Code',
          field: 'code',
        },
        // {
        //   label: 'Status',
        //   field: 'is_active',
        // },
        // {
        //   label: 'Action',
        //   field: 'action',
        // },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  created() {
    this.getLanguages()
    this.actionsArray = [{ title: 'Change Status', icon: 'EditIcon' }]
  },
  methods: {
    getLanguages() {
      this.loading = true
      languagesService.index().then(response => {
        this.rows = response.data.data
        this.loading = false
      }).catch(error => {
        this.loading = false
      })
    },
    handleActions(itemObj) {
      switch (itemObj.key) {
        case 'Change Status':
          this.changeStatus(itemObj.id)
          break
      }
    },
    changeStatus(id) {
      this.$swal({
        title: 'Are you sure of changing activating status?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          languagesService.changeStatus(id)
            .then(response => {
              this.renderTable()
              this.$swal({
                title: 'Process completed Successfully!',
                icon: 'success',
                confirmButtonText: 'Yes',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    },
    getEditedObject(obj) {
      this.editedObject = obj
    },
    catchIds(ids) {
      this.deletedArry = ids
    },
    removeItems(arr) {
      this.confirmDeleteText(arr)
    },

    confirmDeleteText(arr) {
      if (arr.length > 0) {
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            languagesService.delete(arr.join()).then(response => {
              if (response && response.status === 200) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your Items have been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                }).then(() => {
                  this.renderTable()
                })
              }
            }).catch(error => Promise.reject(error))
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: '',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.loading = false
          } else {
            this.loading = false
          }
        })
      }
    },
    renderTable() {
      this.getLanguages()
    },

  },
}
</script>
<style>
input[type="checkbox"]:checked + label::after {
  content: '';
  position: absolute;
  width: 1.2ex;
  height: 0.4ex;
  background: rgba(0, 0, 0, 0)!important;
  top: 0.9ex;
  left: 0.4ex;
  border: 3px solid blue;
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

input[type="checkbox"] {
  line-height: 2.1ex;
}

</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>
