<script
    src="../../../../../../../AppData/Local/Temp/Rar$DRa14036.14120/src/app/helper/alertTimer/alert-timer.service.ts"
>

</script>
<template>
  <!-- The Modal -->
  <div
      id="createModal"
      class="modal"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <button
              type="button"
              class="close"
              data-dismiss="modal"
          >
            &times;
          </button>
        </div>
        <!-- Modal body -->
        <div class="">
          <!--Start-->
          <b-card-code
              title="Create Language"
          >
            <!-- form -->
            <validation-observer ref="simpleRules">
              <b-form>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label for="name">Name</label>
                      <validation-provider
                          #default="{ errors }"
                          name="name"
                          rules="required"
                      >
                        <b-form-input
                            id="Name"
                            v-model="createdLanguage.name"
                            name="name"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
<!--                        <v-select-->
<!--                            v-model="createdLanguage.name"-->
<!--                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
<!--                            :options="titles"-->
<!--                        />-->
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <label for="code">Code</label>
                      <validation-provider
                          #default="{ errors }"
                          name="code"
                          rules="required"
                      >
                        <b-form-input
                            id="code"
                            v-model="createdLanguage.code"
                            name="name"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <!--                        <v-select-->
                        <!--                            v-model="createdLanguage.name"-->
                        <!--                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
                        <!--                            :options="titles"-->
                        <!--                        />-->
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Modal footer -->
                <div class="modal-footer">
                  <button
                      type="button"
                      class="btn btn-primary"
                      @click.prevent="validationForm"
                  >
                    Submit
                  </button>
                </div>
              </b-form>
            </validation-observer>

            <template #code>
              {{ codeMultiple }}
            </template>
          </b-card-code>
          <!--End-->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BFormTextarea,

} from 'bootstrap-vue'
import {
  required, email, confirmed, password,
} from '@validations'
import vSelect from 'vue-select'
import vue2Dropzone from 'vue2-dropzone'
import {codeMultiple, codeBasic} from './code'
import FormTextAreaDefault from '../../../shared/form-textarea/FormTextAreaDefault.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Spinner from '@/shared/loader/Spinner'
import languagesService from '@/services/languagesService'

export default {
  components: {
    BCardCode,
    Spinner,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormTextarea,
    FormTextAreaDefault,
    vSelect,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      createdLanguage: {
        name: '',
        code: ''
      },
      PasswordValue: '',
      passwordCon: '',
      required,
      password,
      email,
      confirmed,
      codeMultiple,
      codeBasic,
      selected: '',
    }
  },
  mounted() {
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate()
          .then(success => {
            if (success) {
              languagesService.post(this.createdLanguage)
                  .then(response => {
                    if (response && response.status === 200) {
                      this.$emit('refresh', 'true')
                      this.createdLanguage = {
                        name: '',
                      }

                      $('#createModal').modal('hide')
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Success',
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: `Language Is Created Successfully !`,
                        },
                      })
                    }

                  })
            }
          })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

label {
  font-weight: bold;
}
</style>
